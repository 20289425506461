import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import getConfig from 'next/config'
import { Entity, IEntity } from '@mpe/api-client/models/entities/entity'
import { Audio } from '@mpe/api-client/models/entities/audio'
import { Connection } from '@mpe/api-client/models/connections/connection'
import { EntityConnection } from '@mpe/api-client/models/entity-connection'
import {
  createConnectionListFromData,
  createEntityFromData
} from '@mpe/api-client/utils/dataConverter'
import { IPagedModelCollection } from '@mpe/api-client/types'
import { Video } from '@mpe/api-client/models/entities/video'

import VideoPlayer from '@/components/VideoPlayer'
import Layout from '@/components/Layout'
import Tiles from '@/components/Tiles'
import TilesHeading from '@/components/TilesHeading'
import EntityDetails from '@/components/EntityDetails/EntityDetails'
import Icon from '@/components/Icon'
import { ConnectionsCount } from '@/components/QuickFacts/QuickFacts'
import { getIdFromSlug, scrollTo } from '@/utils'
import {
  getConnectionsCount,
  getConnectionsPaginated,
  getEntity,
  getMediaConnections,
  getReferenceConnections
} from '@/fetchers'
import useBreadcrumbs from '@/hooks/useBreadcrumbs'
import { entityTypes, Metadata, PATH_TO_ENTITY } from '@/consts'
import useDataLayer from '@/hooks/useDataLayer'
import { StaticEntity } from '@/consts/types'

import Error404 from './404'

export interface EntityPageProps {
  payload?: {
    entity: Entity<any>
    initialConnectedEntities: (Entity<any> & { role: string; localizedRole: string })[]
    initialPagesCount: number
    connections: Connection[]
    connectionsCount: ConnectionsCount
    mediaConnections: Connection[]
    connectedAudios: Audio[]
    referenceConnections: Connection[]
    metadata: Metadata
    host?: string
  }
}

type Sort = 'default' | 'name' | 'dateFromDesc' | 'dateFromAsc'
type SortToQueryParams = {
  [key in Sort]: { orderField: string; orderDirection: 'asc' | 'desc' }
}

export type Theme =
  | 'default'
  | 'Band'
  | 'Person'
  | 'EventSeries'
  | 'Event'
  | 'Image'
  | 'Publication'
  | 'Album'

function appendRoles(connections: EntityConnection[]) {
  const ids = connections.map((item) => item.entity['@id'])
  return connections.reduce(
    (acc: EntityConnection[], curr: EntityConnection, index: number): EntityConnection[] => {
      const localizedRole = curr.localizedRole?.replace('Zenész -', '').toLowerCase().trim()
      const role = curr.role
      if (ids.indexOf(curr.entity['@id']) === index) {
        // if first occurance of the connection add it with role
        const copy = Object.assign({}, curr)
        copy.localizedRole = localizedRole ?? ''
        copy.role = role ?? ''
        return [...acc, copy]
      } else {
        // if already existing with a role concat the other role
        return acc.map((c) => {
          if (c.entity['@id'] === curr.entity['@id']) {
            c.localizedRole =
              localizedRole && !c.localizedRole?.includes(localizedRole)
                ? c.localizedRole?.concat(`, ${localizedRole}`)
                : c.localizedRole
            c.role = role && !c.role?.includes(role) ? c.role?.concat(`, ${role}`) : c.role
          }
          return c
        })
      }
    },
    []
  )
}

function entitiesWithRoles(connections: EntityConnection[]) {
  return connections.map((c) => ({
    ...c.entity,
    role: c.role,
    localizedRole: c.localizedRole
  }))
}

function connectionsToEntities(connections: EntityConnection[]) {
  const withRoles = appendRoles(connections)
  return entitiesWithRoles(withRoles)
}

async function fetchConnectedEntities(
  entityId: string,
  page: number,
  orderField: string,
  orderDirection: string,
  types?: string
) {
  const query = {
    entityId,
    page: page.toString(),
    orderField,
    orderDirection,
    ...(types && { types })
  }
  const queryString = new URLSearchParams(query).toString()
  const response = await fetch(`/api/connections?${queryString}`)
  const connectionsPage = (await response.json()) as IPagedModelCollection
  const pagesCount = connectionsPage?.pagesCount ?? 0
  const entities = connectionsToEntities((connectionsPage?.items as EntityConnection[]) ?? [])
  return { entities, pagesCount }
}

export default function EntityPage({ payload }: EntityPageProps) {
  if (!payload) {
    return null
  }

  const {
    entity: entityData,
    initialConnectedEntities,
    initialPagesCount,
    connectionsCount,
    mediaConnections,
    connectedAudios,
    referenceConnections,
    metadata
  } = payload ?? {}
  let host = payload.host || null
  if (host && !host.includes('http')) {
    host = 'https://' + host
  }

  if (!entityData) {
    return <Error404 />
  }

  const router = useRouter()
  const lang = router.query.lang
  const entity = createEntityFromData(entityData)
  const references = createConnectionListFromData(referenceConnections, entity['@id'])
  const crumbs = entity ? useBreadcrumbs(entity.name) : []
  const connectionsTopRef = useRef(null)
  const dataLayer = useDataLayer()
  const imageConfig = process.env.__NEXT_IMAGE_OPTS as any
  const runtimeConfig = getConfig().publicRuntimeConfig ?? {}

  const [sortBy, setSortBy] = useState<keyof typeof sortToQueryParams>('default')
  const [theme, setTheme] = useState<Theme>('default')
  const [connectedEntities, setConnectedEntities] =
    useState<(IEntity | StaticEntity)[]>(initialConnectedEntities)
  const [connectedEntitiesPageCount, setConnectedEntitiesPageCount] = useState<number | null>(
    initialPagesCount
  )

  async function updateConnections(
    page = 1,
    theme: Theme = 'default',
    sortBy: keyof typeof sortToQueryParams = 'default'
  ) {
    const types = theme === 'default' ? undefined : theme
    const { orderField, orderDirection } = sortToQueryParams[sortBy]

    const { entities, pagesCount } = await fetchConnectedEntities(
      entityData.id.toString(),
      page,
      orderField,
      orderDirection,
      types
    )
    setConnectedEntities(entities)
    setConnectedEntitiesPageCount(pagesCount)
  }

  const sortToQueryParams: SortToQueryParams = {
    default: { orderField: 'type', orderDirection: 'asc' },
    name: { orderField: 'name', orderDirection: 'asc' },
    dateFromDesc: { orderField: 'dateFrom', orderDirection: 'desc' },
    dateFromAsc: { orderField: 'dateFrom', orderDirection: 'asc' }
  }

  const onSortSelectorChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setSortBy(event.target.value as Sort)
    // gtm
    dataLayer.push({
      event: 'sort',
      value: event.target.value
    })
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          order: event.target.value
        }
      },
      undefined,
      { scroll: false }
    )
  }
  const onThemeSelectorChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    setTheme(event.target.value as Theme)
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          theme: event.target.value
        }
      },
      undefined,
      { scroll: false }
    )
  }
  const onThemeChipClick = (value: Theme) => {
    if (Object.keys(entityTypes).includes(value)) {
      scrollTo(document.getElementById('tiles-heading'), -100)
      setTheme(value)
    }
  }

  useEffect(() => {
    const { theme, order } = router.query
    setTheme(theme ? (theme as Theme) : 'default')
    setSortBy(order ? (order as Sort) : 'default')
  }, [router.asPath, entity['@id']])

  useEffect(() => {
    updateConnections(1, theme, sortBy as keyof typeof sortToQueryParams)
  }, [sortBy, theme])

  const notFound = <p>Nincs a keresésnek megfelelő találat.</p>
  const themeSelector = (
    <div className="flex relative md:ml-5 mb-4 md:mb-0">
      <select
        className="appearance-none bg-black rounded-full pl-5 pr-10 py-2 border-white border-2 focus:outline-none w-full cursor-pointer"
        onChange={onThemeSelectorChange}
        value={theme}
      >
        <option value="default" key="default">
          {lang === 'eng' ? 'All' : 'Összes'}
        </option>
        {Object.entries(entityTypes).map(([key, value]) => (
          <option value={key} key={key}>
            {lang === 'eng' ? key : value}
          </option>
        ))}
      </select>
      <Icon name="caret" className="absolute w-4 right-4 top-5" />
    </div>
  )

  let imageUrl = entity.coverImageUrl ?? entity.defaultImageUrl

  if (!imageUrl && entity.previewImages?.length) {
    imageUrl = entity.previewImages[0]
  }

  if (!imageUrl && entity['squareImageUrl']) {
    imageUrl = entity['squareImageUrl']
  }

  // if get parameter is present, render only the video player
  let renderVideoOnly = false
  const { embed, start } = router.query
  if (entity instanceof Video && typeof embed !== 'undefined') {
    renderVideoOnly = true
  }

  return (
    <>
      <Head>
        <title>{entity.name}</title>
        <meta property="og:url" content={`${host}${router.asPath}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={entity.name} />
        <meta property="og:description" content={entity.description ?? metadata.description} />
        <meta
          property="og:image"
          content={`${imageConfig.path}${imageUrl ?? metadata.imgUrl}`.replace(
            /(\w)\/\/(\S)/g,
            '$1/$2'
          )}
        />
        <meta property="fb:app_id" content={runtimeConfig.fbAppId} />
      </Head>
      {renderVideoOnly ? (
        <div className="absolute w-full h-full flex align-items-center">
          <VideoPlayer
            entity={entity as Video}
            embedded
            startAt={start ? parseInt(start as any) : 0}
          />
        </div>
      ) : (
        <Layout breadcrumbs={crumbs}>
          <div className="container-padded pt-28">
            <EntityDetails
              topRef={connectionsTopRef}
              entity={entity}
              mediaConnections={createConnectionListFromData(mediaConnections, entity['@id'])}
              referenceConnections={references}
              connectedAudios={connectedAudios}
              connectionsCount={connectionsCount}
              onThemeChipClick={onThemeChipClick}
            />
          </div>

          <div className="container-padded pt-10">
            <TilesHeading
              topRef={connectionsTopRef}
              title={lang === 'eng' ? 'Connections' : 'Kapcsolatok'}
              subtitle={lang === 'eng' ? 'Content in Hungarian' : undefined}
              selector={sortBy}
              onSelectorChange={onSortSelectorChange}
              selectorOptions={{
                default: lang === 'eng' ? 'Order by' : 'Rendezés',
                name: lang === 'eng' ? 'Name' : 'Név',
                dateFromAsc: lang === 'eng' ? 'Year (ascending) ' : 'Évszám (növekvő)',
                dateFromDesc: lang === 'eng' ? 'Year (descending) ' : 'Évszám (csökkenő)'
              }}
              hideMarginTop
            >
              {themeSelector}
            </TilesHeading>
            {
              // eslint-disable-next-line no-extra-boolean-cast
              !!connectedEntities.length ? (
                <Tiles
                  initialEntities={connectedEntities}
                  topRef={connectionsTopRef}
                  onPageChange={(page: number) => updateConnections(page, theme, sortBy)}
                  pagesCount={connectedEntitiesPageCount || 0}
                />
              ) : (
                notFound
              )
            }
          </div>
        </Layout>
      )}
    </>
  )
}

export async function getServerSideProps({ query, req, res }: GetServerSidePropsContext) {
  res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59')

  const { entity } = query
  const parts = entity ? entity.toString().split('-') : []
  const category = parts.shift()
  const slug = parts.join('-')
  const { host } = req.headers

  let payload: { [key: string]: any } = {}

  if (category && slug) {
    const { type, endpoint, metadata } = PATH_TO_ENTITY[category] ?? {}

    if (type && endpoint) {
      const entityId = getIdFromSlug(slug)
      const iri = `${endpoint}/${entityId}`

      const entity = await getEntity(iri)
      if (!entity)
        return {
          notFound: true
        }

      const connectionsPage = await getConnectionsPaginated(entityId, 1, 'type', 'asc')
      const connectedEntities = connectionsToEntities(
        (connectionsPage?.items as EntityConnection[]) ?? []
      )
      const connectionsCount = await getConnectionsCount(entityId)
      const referenceConnections = (await getReferenceConnections(iri)) ?? []
      const mediaConnections =
        entity.type === 'Album' ? await getMediaConnections(iri, ['Image']) : []

      const audioConnections =
        entity.type === 'MusicAlbum' ? await getMediaConnections(iri, ['Audio']) : null
      const connectedAudios =
        audioConnections && audioConnections.map((c: Connection) => c.toEntity)

      payload = {
        entity: entity ?? null,
        initialConnectedEntities: connectedEntities,
        initialPagesCount: connectionsPage?.pagesCount ?? 0,
        connectionsCount,
        mediaConnections: mediaConnections || [],
        connectedAudios,
        referenceConnections,
        metadata,
        host
      }
    }
  }

  return {
    props: {
      key: slug,
      payload
    }
  }
}
