import { MusicAlbum } from '@mpe/api-client/models/entities/music-album'
import { Audio } from '@mpe/api-client/models/entities/audio'
import SingleAudioPlayer from '../SingleAudioPlayer'
import { useState } from 'react'

interface AlbumTracklistProps {
  entity: MusicAlbum
  tracks: Audio[]
}

export default function AlbumTrackList({ entity, tracks }: AlbumTracklistProps) {
  if (!(entity instanceof MusicAlbum)) return null

  const [track, setTrack] = useState<Audio | null>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [showSingleAudioPlayer, setShowSingleAudioPlayer] = useState(false)
  function play(track: Audio) {
    setTrack(track)
    setShowSingleAudioPlayer(true)
  }

  return (
    <>
      <div className="px-4 md:px-10 my-6">
        <h2>Számlista</h2>

        <ul className="list-disc list-inside mt-3">
          {tracks?.map((track) => (
            <li
              className={`cursor-pointer hover:underline`}
              key={track.id}
              onClick={() => {
                play(track)
              }}
            >
              {track.name}
            </li>
          ))}
        </ul>
      </div>
      {track && (
        <SingleAudioPlayer
          entity={track}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
          setShowSingleAudioPlayer={setShowSingleAudioPlayer}
          showSingleAudioPlayer={showSingleAudioPlayer}
          autoPlay={true}
        />
      )}
    </>
  )
}
